import dynamic from "next/dynamic";

import HomepageHeroCardGrid from "@/components/cardGrid/HomepageCardGridWithHero";
import Head from "@/components/Head";
import TopSideLayout from "@/components/layouts/TopSideLayout";
import Tagline from "@/components/Tagline";
import TopBannerImageHomepage from "@/components/TopBannerImageHomepage";
import TwitchEmbedded from "@/components/TwitchEmbedded";
import { REVALIDATION_TIME } from "@/config/constants";
import labels from "@/config/labels";
import useScreenWidth from "@/hooks/useScreenWidth";
import { formatImageForSEO } from "@/utilities/image";
import ShortCodes from "@/utilities/shortcodes";

import {
  getAffiliates,
  getBanners,
  getCategoryUris,
  getCTASportbooks404Shortcode,
  getPageContentByURI,
  getTopPosts,
} from "./api/apiWP";

const BestBetsCard = dynamic(
  () => import("@/components/sidebar/components/BestBetsCard")
);
const GuidesCard = dynamic(
  () => import("@/components/sidebar/components/GuidesCard")
);
const NewsCard = dynamic(
  () => import("@/components/sidebar/components/NewsCard")
);
const Newsletter = dynamic(
  () => import("@/components/sidebar/components/Newsletter")
);
const SportsbookPromoShortcodes = dynamic(
  () => import("@/components/SportsbookPromoShortcodes")
);

const Home = ({
  page,
  topPosts,
  siteContent,
  banners,
  affiliates,
  shortcode,
}) => {
  const homepageBanner = banners?.find(
    (banner) => banner?.title === "banner-homepage"
  );
  const isHomepageBannerActive = homepageBanner?.bannerInfo?.active;

  const secondaryBanner = banners?.find(
    (banner) => banner?.title === "banner-homepage-secondary"
  );
  const isSecondaryBannerActive = secondaryBanner?.bannerInfo?.active;

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 991;

  const affiliateBGImages =
    affiliates?.length > 0
      ? affiliates
          ?.map((affiliate) => affiliate?.background)
          ?.slice(0, isMobile ? 2 : 4)
      : [];

  const imagesToPreload = formatImageForSEO([
    ...affiliateBGImages,
    isHomepageBannerActive ? homepageBanner?.bannerInfo?.src : "",
  ]);

  return (
    <TopSideLayout
      siteContent={siteContent}
      banner={homepageBanner}
      isBannerActive={isHomepageBannerActive}
    >
      <Head isHome seo={page?.seo} imagesToPreload={imagesToPreload} />
      <ShortCodes />
      {isHomepageBannerActive && (
        <div className="hidden lg:block">
          <TopBannerImageHomepage banner={homepageBanner} />
        </div>
      )}
      {isSecondaryBannerActive && (
        <TopBannerImageHomepage banner={secondaryBanner} />
      )}
      <div>
        <Tagline />
        <TwitchEmbedded />
        <div className="mb-2 flex flex-col justify-between sm:flex-row sm:items-end">
          <h2 className="font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem]">
            {labels?.homepageNewsTitle}
          </h2>
        </div>
        <HomepageHeroCardGrid items={topPosts}>
          <div className="lg:hidden">
            <BestBetsCard />
          </div>
          {isMobile ? (
            <>
              <GuidesCard />
              <div className="text-white">
                <Newsletter />
              </div>
            </>
          ) : (
            shortcode && <SportsbookPromoShortcodes shortcode={shortcode} />
          )}
        </HomepageHeroCardGrid>
        <div className="lg:hidden">
          <NewsCard />
        </div>
      </div>
    </TopSideLayout>
  );
};

export async function getStaticProps() {
  const homePageData = await Promise.all([
    getCategoryUris(),
    getPageContentByURI("/"),
    getTopPosts(),
    getAffiliates(),
    getCTASportbooks404Shortcode(),
    getBanners(),
  ]);

  return {
    props: {
      categoryUris: homePageData?.[0],
      page: homePageData?.[1],
      siteContent: homePageData?.[1]?.content || "",
      topPosts: homePageData?.[2],
      affiliates: homePageData?.[3],
      shortcode: homePageData?.[4],
      banners: homePageData?.[5],
    },
    revalidate: REVALIDATION_TIME,
  };
}

export default Home;
